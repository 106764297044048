.calc h2 {
  font-family: Montserrat;
  font-size: 3.3vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 4.0625vw */
  letter-spacing: -0.13541666666666669vw;
  padding-top: 2.5vw;
}
.leftCalc > input {
  border-radius: 52.083333333333336vw;
  width: 22vw;
  padding: 1vw 1.5vw;
  background: #f5f7f9;
  font-family: Inter;
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 0.9375vw */
  letter-spacing: -0.052083333333333336vw;
  border: none;
  outline: none;
  margin-top: 1vw;
  margin-bottom: 1.4vw;
}
.leftCalc {
  padding-right: 3vw;
  border-right: 0.052083333333333336vw solid #e8edf2;
  margin-right: 3vw;
}
.calc {
  display: flex;
}
.warn {
  display: flex;
  align-items: flex-end;
}
.warn div {
  width: 26vw;
  display: flex;
  align-items: flex-start;

  font-family: Montserrat;
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.052083333333333336vw;
  margin-left: 3vw;
  position: relative;
  top: -0.7vw;
}
.warn img {
  width: 1vw;
  margin-right: 0.1vw;
}
.numsic {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vw;
}
.linik {
  width: 0.10416666666666667vw;
  background: #a8b0b7;
  height: 0.5vw;
  margin-bottom: 0.3vw;
}
.numsic > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1vw;
}
.warn div p {
  color: #a8b0b7;
}
.lod {
  width: 47vw;
  margin-top: 1vw;
  margin-bottom: 1.4vw;
  cursor: pointer;
}
.warn div b {
  color: black;
}
.sale {
  color: #fff;
  text-align: center;
  font-family: Inter;
  border-radius: 52.083333333333336vw;
  background: #6ed375;
  padding: 0.8vw;
  box-shadow: 0.052083333333333336vw 0.5138541666666666vw 1.0276562500000002vw
    0.052083333333333336vw rgba(110, 211, 117, 0.2);
  font-size: 2vw;
  transition: 0.3s;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  line-height: 120%; /* 2.5vw */
  letter-spacing: -0.10416666666666667vw;
  margin-bottom: 1.4vw;
}
.sale:hover {
  background: #48ac4f;
}
input[type="range"] {
  height: 1.9270833333333335vw;
  -webkit-appearance: none;
  margin: 0.5208333333333333vw 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1.9270833333333335vw;
  cursor: pointer;
  animate: 0.2s;

  background: #71d578;
  border-radius: 0.26041666666666663vw;
  border: 0.20833333333333334vw solid #f2f3f5;
}
input[type="range"]::-webkit-slider-thumb {
  border: 0.052083333333333336vw solid #ffffff;
  height: 3.125vw;
  width: 3.125vw;
  border-radius: 0.552083333333333336vw;
  background: url(./img/th.svg);
  background-size: cover;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -0.8333333333333334vw;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #71d578;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 1.9270833333333335vw;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0.052083333333333336vw 0.052083333333333336vw
    0.052083333333333336vw #353535;
  background: #71d578;
  border-radius: 0.26041666666666663vw;
  border: 0.20833333333333334vw solid #f2f3f5;
}
input[type="range"]::-moz-range-thumb {
  border: 0.052083333333333336vw solid #ffffff;
  height: 3.125vw;
  width: 3.125vw;
  border-radius: 0.052083333333333336vw;
  background: #fff6f6;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 1.9270833333333335vw;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #71d578;
  border: 0.20833333333333334vw solid #f2f3f5;
  border-radius: 0.052083333333333336vw;
}
input[type="range"]::-ms-fill-upper {
  background: #71d578;
  border: 0.20833333333333334vw solid #f2f3f5;
  border-radius: 0.052083333333333336vw;
  box-shadow: 0.052083333333333336vw 0.052083333333333336vw
    0.052083333333333336vw #353535;
}
input[type="range"]::-ms-thumb {
  margin-top: 0.052083333333333336vw;
  box-shadow: 0.052083333333333336vw 0.052083333333333336vw
    0.052083333333333336vw #353535;
  border: 0.052083333333333336vw solid #ffffff;
  height: 3.125vw;
  width: 3.125vw;
  border-radius: 0.052083333333333336vw;
  background: #fff6f6;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #71d578;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #71d578;
}
.calc h3 {
  color: var(--, #000);
  font-family: Montserrat;
  font-size: 1.9vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 2.2500000000000004vw */
  letter-spacing: -0.075vw;
  margin-bottom: 1.4vw;
}
.economy {
  border-radius: 0.9681770833333332vw;
  background: #fff;
  padding: 1.5vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.3vw;
  cursor: pointer;
  transition: 0.5s;
  padding-left: 1.3vw;
  padding-right: 3.3vw;
  box-shadow: 0.052083333333333336vw 0.5138541666666666vw 1.0276562500000002vw
    0.052083333333333336vw rgba(112, 151, 199, 0.2);
}
.gridDay {
  width: 47vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1.7vw;
  grid-row-gap: 1.7vw;
}
.economy > p {
  font-family: Montserrat;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 1.1458333333333333vw */
  letter-spacing: -0.057291666666666664vw;
}
.gridEm {
  padding: 1.5vw;
  border-radius: 1vw;
  transition: 0.3s;
  background: #fff;
  box-shadow: 0.052083333333333336vw 0.5918229166666666vw 1.1836979166666668vw
    0.052083333333333336vw rgba(112, 151, 199, 0.2);
}
.gridEm p {
  font-family: Inter;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0.6vw;
  line-height: 100%;
  letter-spacing: -0.06598958333333332vw;
}
.gridEm p span {
  color: #a8b0b7;
}
.gridEm > span {
  color: #a8b0b7;
  font-family: Montserrat;
  font-size: 0.65vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.052083333333333336vw;
}
.gridEm:hover {
  transform: translateY(-7px);
  cursor: pointer;
  box-shadow: 0.052083333333333336vw 0.2793229166666667vw 0.5066145833333333vw
    0.052083333333333336vw rgba(112, 151, 199, 0.2);
}
.gridEm h4 {
  font-family: Montserrat;
  font-size: 1vw;
  margin-bottom: 0.8vw;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 1.01984375vw */
  letter-spacing: -0.052083333333333336vw;
}
.economy div p {
  margin-bottom: 0.6vw;

  font-family: Inter;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 1.1458333333333333vw */
  letter-spacing: -0.057291666666666664vw;
}
.economy div p:last-child {
  margin-bottom: 0;
}
.economy div p span {
  color: #a8b0b7;
}
.economy:hover {
  transform: translateY(-7px);
  box-shadow: 0.052083333333333336vw 0.3055208333333333vw 0.2984895833333333vw
    0.052083333333333336vw rgba(112, 151, 199, 0.2);
}
.bit {
  width: 47vw;
  margin-top: 0.8vw;
}
@media (max-width: 640px) {
  .calc {
    flex-direction: column;
  }
  .economy {
    display: none;
  }
  .calc h3 {
    display: none;
  }
  .terH {
    padding-top: 14.5vw !important;
  }
  .calc h2 {
    font-family: Montserrat;
    font-size: 10.3vw;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.13541666666666669vw;
    padding-top: 2.5vw;
  }
  .leftCalc > input {
    border-radius: 52.083333333333336vw;
    width: 100%;
    padding: 3vw 3.5vw;
    background: #f5f7f9;
    font-family: Inter;
    font-size: 5vw;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.052083333333333336vw;
    border: none;
    outline: none;
    margin-top: 3vw;
    margin-bottom: 5.4vw;
  }
  .leftCalc {
    margin-right: 0;
    border: none;
    padding-right: 0;
  }
  .sale {
    color: #fff;
    text-align: center;
    font-family: Inter;
    border-radius: 52.083333333333336vw;
    background: #6ed375;
    padding: 3vw;
    box-shadow: 0.052083333333333336vw 0.5138541666666666vw 1.0276562500000002vw
      0.052083333333333336vw rgba(110, 211, 117, 0.2);
    font-size: 5vw;
    transition: 0.3s;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    line-height: 120%;
    letter-spacing: -0.10416666666666667vw;
    margin-bottom: 1.4vw;
  }
  .flexis {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 7vw;
  }
  .flexis h2 {
    text-align: center;
    font-family: Montserrat;
    font-size: 11vw;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.052083333333333336vw;
    margin-right: 1.8vw;
    margin-bottom: 5vw;
  }
  .inp {
    width: 100%;
  }
  .flexis input {
    border-radius: 3.5vw;
    background: #f5f7f9;
    padding: 2.8vw 3.5vw;
    color: var(--, #a8b0b7);
    font-family: Inter;
    width: 100%;
    font-size: 5.3vw;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.052083333333333336vw;
    border: none;

    outline: none;
  }
  #kolom {
    width: 100%;
  }
  .numsic {
    width: 100%;
  }
  input[type="range"] {
    height: 4.927083vw;
    -webkit-appearance: none;
    margin: 0.5208333333333333vw 0;
    width: 100%;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4.9270833333333335vw;
    cursor: pointer;
    animate: 0.2s;

    background: #71d578;
    border-radius: 0.26041666666666663vw;
    border: 0.20833333333333334vw solid #f2f3f5;
  }
  input[type="range"]::-webkit-slider-thumb {
    border: 0.052083333333333336vw solid #ffffff;
    height: 9.125vw;
    width: 9.125vw;
    border-radius: 0.552083333333333336vw;
    background: url(./img/th.svg);
    background-size: cover;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -2.3333333333333334vw;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #71d578;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 1.9270833333333335vw;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0.052083333333333336vw 0.052083333333333336vw
      0.052083333333333336vw #353535;
    background: #71d578;
    border-radius: 0.26041666666666663vw;
    border: 0.20833333333333334vw solid #f2f3f5;
  }
  input[type="range"]::-moz-range-thumb {
    border: 0.052083333333333336vw solid #ffffff;
    height: 3.125vw;
    width: 3.125vw;
    border-radius: 0.052083333333333336vw;
    background: #fff6f6;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 1.9270833333333335vw;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #71d578;
    border: 0.20833333333333334vw solid #f2f3f5;
    border-radius: 0.052083333333333336vw;
  }
  input[type="range"]::-ms-fill-upper {
    background: #71d578;
    border: 0.20833333333333334vw solid #f2f3f5;
    border-radius: 0.052083333333333336vw;
    box-shadow: 0.052083333333333336vw 0.052083333333333336vw
      0.052083333333333336vw #353535;
  }
  input[type="range"]::-ms-thumb {
    margin-top: 0.052083333333333336vw;
    box-shadow: 0.052083333333333336vw 0.052083333333333336vw
      0.052083333333333336vw #353535;
    border: 0.052083333333333336vw solid #ffffff;
    height: 3.125vw;
    width: 3.125vw;
    border-radius: 0.052083333333333336vw;
    background: #fff6f6;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #71d578;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #71d578;
  }
  .linik {
    width: 0.10416666666666667vw;
    background: #a8b0b7;
    height: 2.5vw;
    margin-bottom: 0.3vw;
  }
  .gridDay {
    display: flex;
    overflow-x: scroll;
    padding: 6vw 2.5vw;
    width: 100vw;
    margin-left: -2.5vw;
  }
  .gridDay::-webkit-scrollbar {
    display: none;
  }
  .bit {
    width: 100vw;
    margin-left: -2.5vw;
    padding-bottom: 10vw;
  }
  .gridEm h4 {
    font-family: Montserrat;
    font-size: 5.25vw;
    font-style: normal;
    margin-bottom: 4vw;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.052083333333333336vw;
  }
  .inden {
    display: flex;
    align-items: center;
    color: #000;
    justify-content: center;
    font-family: Inter;
    font-size: 3.7vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.052083333333333336vw;
    margin-top: 5.5vw;
    margin-bottom: 2vw;
  }
  .att img {
    width: 11.3vw;
  }
  .att span {
    width: 90%;
    color: #a8b0b7;
    font-family: Montserrat;
    font-size: 3vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.052083333333333336vw;
  }
  .tera > img {
    margin-top: 9.5vw;
    width: 100%;
    margin-left: 0;
    margin-bottom: 5vw;
  }
  .att {
    margin-top: 0;
  }
  .inden img {
    margin: 0 2vw;
    width: 7.5vw;
  }
  .flexDay {
    width: fit-content;
  }
  .gridEm p {
    color: #000;
    font-family: Inter;
    font-size: 4.6vw;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.052083333333333336vw;
    margin-bottom: 3vw;
  }
  .gridEm {
    min-width: 40vw;
  }
  .warn {
    margin-bottom: 6vw;
    flex-direction: column;
    align-items: flex-start;
  }
  .warn div {
    width: 100%;
    display: flex;
    align-items: flex-start;
    font-family: Montserrat;
    font-size: 3.3vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.052083333333333336vw;
    margin-left: 0;
    position: relative;
    top: 0;
    margin-top: 2vw;
  }
  .warn img {
    width: 4vw;
    margin-right: 1.1vw;
  }
  .gridEm {
    border-radius: 3.5vw;
    padding: 3.8vw 3.5vw;
    margin: 0 1vw;
    background: #fff;
    box-shadow: 0.052083333333333336vw 0.78125vw 1.5625vw 0.052083333333333336vw
      rgba(112, 151, 199, 0.2);
  }
  .gridEm > span {
    color: #a8b0b7;
    font-family: Inter;
    font-size: 3.8vw;
    font-style: normal;
    font-weight: 500;
    display: block;
    margin-top: 4vw;
    line-height: normal;
    letter-spacing: -0.052083333333333336vw;
  }
}
