.leftProfile h2,
.rightrofile h2 {
  font-family: Montserrat;
  font-size: 4vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.20833333333333334vw;
  margin-left: 1vw;
  padding-top: 2.5vw;
}
.profile {
  display: flex;
  justify-content: flex-end;
}
.leftProfile > p,
.rightrofile > p {
  color: #77828c;
  font-family: Inter;
  font-size: 1vw;
  margin-left: 1vw;
  margin-top: 0.5vw;
  margin-bottom: 2.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.052083333333333336vw;
}
.leftProfile {
  width: 31vw;
}
.profile .inputAuth {
  margin-bottom: 1vw;
}
.rightrofile {
  border-left: 0.052083333333333336vw solid #e8edf2;
  padding-right: 5.6vw;
  padding-left: 7.3vw;
  margin-left: 7.5vw;
}
.rightrofile img {
  width: 12vw;
  cursor: pointer;
}
.leftProfile .flexInputs {
  align-items: center;
}
.savePassword {
  display: flex;
  align-items: center;
  background: #0370f3;
  color: #fff;
  padding: 0.8vw 1vw;
  border-radius: 52.083333333333336vw;
  font-size: 0.9vw;
  cursor: pointer;
  margin-top: 0.3vw;
  transition: 0.3s;
}
.savePassword img {
  margin-left: 0.5vw;
  width: 1vw;
}
.withButton input {
  width: 11vw !important;
}
.saveButton {
  display: flex;
  align-items: center;
  background: #0370f3;
  border-radius: 52.083333333333336vw;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 1.2vw;
  font-style: normal;
  justify-content: center;
  font-weight: 600;
  line-height: 160%; /* 2vw */
  letter-spacing: -0.0625vw;
  padding: 1vw;
  cursor: pointer;
  margin-top: 1.2vw;
  transition: 0.3s;
}
.saveButton:hover,
.savePassword:hover {
  background: #0150b1;
}
.saveButton img {
  width: 1.3vw;
  margin-left: 1vw;
}
.saveButton p {
  margin-left: 1.5vw;
}

@media (max-width: 640px) {
  .leftProfile {
    width: 100%;
  }
  .profile {
    flex-direction: column;
  }
  .profile .inputAuth {
    margin-bottom: 6vw;
  }
  .withButton input {
    width: 100% !important;
  }
  .leftProfile h2,
  .rightrofile h2 {
    font-family: Montserrat;
    font-size: 11vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.20833333333333334vw;
    margin-left: 1vw;
    padding-top: 9.5vw;
  }
  .leftProfile > p,
  .rightrofile > p {
    color: #77828c;
    font-family: Inter;
    font-size: 4vw;
    margin-left: 1vw;
    margin-top: 0.5vw;
    margin-bottom: 10.3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.052083333333333336vw;
  }
  .savePassword {
    display: none;
  }
  .saveButton {
    display: flex;
    align-items: center;
    background: #0370f3;
    border-radius: 52.083333333333336vw;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 5.2vw;
    font-style: normal;
    justify-content: center;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: -0.0625vw;
    padding: 5vw;
    cursor: pointer;
    margin-top: 1.2vw;
    transition: 0.3s;
  }
  .saveButton img {
    width: 4.3vw;
    margin-left: 2vw;
  }
  .rightrofile {
    margin-left: 0;
    padding-left: 0;
    border: none;
    display: flex;
    align-items: center;
    margin-top: 8vw;
    justify-content: center;
    padding-bottom: 10vw;
  }
  .rightrofile p {
    display: none;
  }
  .rightrofile h2 {
    padding-top: 0;
    margin-right: 5vw;
  }
  .rightrofile img {
    width: 27vw;
  }
}
