.navBar {
  background: #f5f7f9;
  width: 17vw;
  padding: 4vw 2.5vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.navBar > img {
  width: 11.7vw;
  margin-bottom: 3.5vw;
}
.kek {
  border-radius: 0.5vw;
  background: #00316c;
  border-radius: 0.5vw;
  filter: brightness(2.2)
    drop-shadow(
      0.052083333333333336vw 0.3125vw 0.78125vw rgba(1, 79, 173, 0.35)
    );
}
ul {
  list-style-type: none;
}
.navBar ul li a {
  display: flex;
  align-items: center;
  color: #000;
  font-family: Inter;
  font-size: 1vw;
  font-style: normal;
  text-decoration: none;
  font-weight: 400;
  line-height: normal;
  transition: 0.3s;
  letter-spacing: -0.052083333333333336vw;
}
/* .navBar ul li a p {
  transition: 0.3s;
} */
.str {
  font-weight: 600;
  letter-spacing: 0.1vw;
}
.navBar ul li a img {
  width: 2.8vw;
  margin-right: 0.8vw;
  transition: 0.3s;
  border-radius: 0.5vw;
}
.navBar ul li {
  padding: 1.5vw 0;
  border-bottom: 0.052083333333333336vw solid #dce5ee;
}
.navBar ul li:last-child {
  border-bottom: none;
}
.navBar ul li a:hover {
  letter-spacing: 0.1vw;
}
@media (max-width: 640px) {
  .navBar {
    background: #f5f7f9;
    width: 100vw;
    padding: 8vw 4vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 500;
    transition: 0.7s;
    left: 0;
  }
  .navBar ul li a {
    display: flex;
    align-items: center;
    color: #000;
    font-family: Inter;
    font-size: 5vw;
    font-style: normal;
    text-decoration: none;
    font-weight: 400;
    line-height: normal;
    transition: 0.3s;
    letter-spacing: -0.052083333333333336vw;
  }
  .navBar ul li {
    padding: 4.5vw 0;
    border-bottom: 0.052083333333333336vw solid #dce5ee;
  }
  .navBar ul li a img {
    width: 9.8vw;
    margin-right: 2.8vw;
    transition: 0.3s;
    border-radius: 2vw;
  }
  .navBar > img {
    width: 56.7vw;
    margin-bottom: 6.5vw;
  }
  .closeM {
    font-size: 5vw;
    background: #0370f3;
    padding: 5vw;
    width: 100%;
    text-align: center;
    color: #fff;
    font-weight: 600;
    border-radius: 30vw;
    margin-top: 3vw;
  }
}
