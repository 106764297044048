.leftDash h2 {
  font-family: Montserrat;
  font-size: 4vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.20833333333333334vw;
  width: 32vw;
  height: 9.5vw;
  margin-top: 2.5vw;
}
.redText {
  color: #77828c;
  font-family: Inter;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.052083333333333336vw;
  margin-top: 0.5vw;
  margin-bottom: 1.5vw;
}
.redText b {
  color: #000;
  font-weight: 600;
}
.redText span {
  color: #ff4545;
  font-family: Inter;
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.052083333333333336vw;
}
.lighting {
  color: #626262;
  font-family: Inter;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 2vw */
  letter-spacing: -0.0625vw;
  margin-bottom: 1.5vw;
}
.buttonCalc {
  padding: 1vw 2.25vw;
  border-radius: 52.083333333333336vw;
  background: #0370f3;
  color: #fff;
  text-align: center;
  font-family: Inter;
  width: fit-content;
  cursor: pointer;
  transition: 0.5s;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 2vw */
  letter-spacing: -0.0625vw;
}
.dashboard {
  display: flex;
  justify-content: space-between;
}
.rightDash {
  background-size: cover;
  display: flex;
  width: 44.63vw;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 44vw;
  letter-spacing: -0.125vw;
}
.referal {
  display: flex;
  align-items: center;
  border-radius: 0.5vw;
  padding: 0.5vw 1vw;
  background: #f5f7f9;
  margin-top: 2.5vw;
  width: fit-content;
}
.referal img {
  width: 1.5vw;
  margin-right: 0.8vw;
}
.referal p {
  font-family: Inter;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.8333333333333333vw */
  letter-spacing: -0.057291666666666664vw;
}
.stat {
  width: 33vw;
  margin-top: 2.5vw;
  padding-top: 2.5vw;
  border-top: 0.052083333333333336vw solid #e8edf2;
}
.buttonCalc {
  transition: 0.5s;
}
.buttonCalc:hover {
  background: #0052b7;
}
@media (max-width: 640px) {
  .dashboard {
    flex-direction: column;
  }
  .leftDash h2 {
    font-family: Montserrat;
    font-size: 10vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.20833333333333334vw;
    width: 100%;
    height: 25vw;
    margin-top: 14.5vw;
  }
  .redText {
    color: #77828c;
    font-family: Inter;
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.052083333333333336vw;
    margin-top: 3.5vw;
    margin-bottom: 4.5vw;
  }
  .redText span {
    color: #ff4545;
    font-family: Inter;
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.052083333333333336vw;
  }
  .lighting {
    color: #626262;
    font-family: Inter;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.0625vw;
    margin-bottom: 8.5vw;
  }
  .referal p {
    font-family: Inter;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.057291666666666664vw;
  }
  .referal img {
    width: 6.5vw;
    margin-right: 3.8vw;
  }
  .referal {
    display: flex;
    align-items: center;
    border-radius: 1.5vw;
    padding: 2.5vw 3vw;
    background: #f5f7f9;
    margin-top: 8.5vw;
    width: -moz-fit-content;
    width: fit-content;
  }
  .rightDash {
    width: 100%;
    height: 100vw;
    padding-bottom: 10vw;
  }
  .buttonCalc {
    padding: 5vw 12.25vw;
    border-radius: 52.083333333333336vw;
    background: #0370f3;
    color: #fff;
    text-align: center;
    font-family: Inter;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    transition: 0.5s;
    font-size: 4.25vw;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: -0.0625vw;
  }
  .stat {
    width: 100%;
    margin-top: 8.5vw;
    padding-top: 8.5vw;
    border-top: 0.052083333333333336vw solid #e8edf2;
  }
}
