.header {
  position: fixed;
  width: 83vw;
  left: 17vw;
  top: 0;
  display: flex;
  align-items: center;
  padding: 1.5vw 2.5vw;
  justify-content: space-between;
  border-bottom: 0.052083333333333336vw solid #e8edf2;
  z-index: 3;
  background: #fff;
}
.search {
  display: flex;
  align-items: center;
  border-radius: 52.083333333333336vw;
  background: #f5f7f9;
  padding: 0.3vw;
  padding-left: 0.5vw;
  transition: 0.3s;
}
.search:hover img {
  transform: scale(1.2);
}
.search img {
  width: 1.5vw;
  transition: 0.3s;
  margin-right: 0.4vw;
}
.search input {
  border: none;
  outline: none;
  background: #f5f7f9;
  font-family: Inter;
  font-size: 0.95vw;
  font-style: normal;
  width: 24vw;
  font-weight: 400;
  line-height: 160%; /* 1.5000000000000002vw */
  letter-spacing: -0.052083333333333336vw;
}
.searchButton {
  background: #0370f3;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 0.8vw;
  padding: 0.5vw 1.4vw;
  border-radius: 52.083333333333336vw;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.3333333333333335vw */
  letter-spacing: -0.052083333333333336vw;
  cursor: pointer;
  transition: 0.3s;
}
.searchButton:hover {
  background: #0152b5;
}
.headerProfile {
  display: flex;
  align-items: center;
  margin-left: 2vw;
  background: #f5f7f9;
  border-radius: 52.083333333333336vw;
  padding: 0.5vw;
}
.logout {
  color: #000;
  font-family: Inter;
  font-size: 0.95vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.052083333333333336vw;
  display: flex;
  align-items: center;
  margin-left: 1.5vw;
  cursor: pointer;
  transition: 0.3s;
}
.logout:hover {
  letter-spacing: 0.1vw;
}
.logout img {
  width: 2vw;
  margin-right: 0.5vw;
}
.header > img {
  margin-left: auto;
  width: 2.2vw;
  cursor: pointer;
  transition: 0.3s;
}
.header > img:hover {
  transform: scale(1.2);
}
.headerProfile .ava {
  width: 3vw;
  margin-right: 1vw;
}
.headerProfile p {
  color: var(--, #000);
  font-family: Inter;
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 1.0416666666666665vw */
  letter-spacing: -0.052083333333333336vw;
  margin-right: 1vw;
}
.settings {
  width: 1.5vw;
  margin-right: 0.7vw;
  cursor: pointer;
  transition: 0.3s;
}
.settings:hover {
  transform: scale(1.2);
}
@media (max-width: 640px) {
  .search {
    display: none;
  }
  .header > img {
    display: none;
  }
  .logout {
    display: none;
  }
  .headerProfile {
    display: none;
  }
  .hdlogo {
    display: block !important;
    width: 45vw !important;
    margin-left: 0 !important;
  }
  .header {
    width: 100vw;
    left: 0;
    padding: 3.5vw 2.5vw;
  }
  .mobileButton {
    width: 10vw;
    height: 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vw;
    background: #0370f3;
    border-radius: 1vw;
    justify-content: space-around;
  }
  .mobileButton div {
    width: 100%;
    background: #fff;
    height: 2px;
  }
}
