.register .rightAuth img {
  width: 41.4583vw;
  margin-left: 0;
}
.flexInputs {
  display: flex;
  justify-content: space-between;
}
.flexInputs input {
  width: 14.5vw;
}
.zvezd {
  color: #0370f3;
  font-family: Inter;
  display: inline;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.052083333333333336vw;
}
.error {
  border: 0.10416666666666667vw solid rgb(255, 52, 52) !important;
}
.login {
  transition: 0.5s;
}
.login:hover {
  background: #024fad;
  letter-spacing: 0.15vw;
}
.agreement {
  display: flex;
  color: #77828c;
  font-family: Inter;
  font-size: 1vw;
  font-style: normal;
  align-items: center;
  justify-content: center;
  margin-top: 2vw;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.052083333333333336vw;
}
.agreement a {
  color: #0370f3;
  font-weight: 700;
  text-decoration: none;
}
.agreement input {
  width: 1.2vw;
  height: 1.2vw;
  margin-right: 0.5vw;
}

@media (max-width: 640px) {
  .flexInputs {
    width: 100%;
    flex-direction: column;
  }
  .flexInputs input {
    width: 100%;
  }
  .agreement {
    display: flex;
    color: #77828c;
    font-family: Inter;
    font-size: 3.5vw;
    font-style: normal;
    align-items: center;
    justify-content: center;
    margin-top: 4vw;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.052083333333333336vw;
  }
  .agreement input {
    width: 4vw;
    height: 4vw;
    margin-right: 1.5vw;
  }
  .register .rightAuth img {
    width: 85%;
    margin-left: 0;
  }
}
