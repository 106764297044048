.FAQ {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 3.5vw;
}
.FAQ h2 {
  color: var(--, #000);
  font-family: Montserrat;
  font-size: 4vw;
  margin-top: 2.5vw;
  margin-bottom: 2.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 5.729166666666666vw */
  letter-spacing: -0.20833333333333334vw;
}
.faqEmBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faqEm {
  border: 0.052083333333333336vw solid rgba(245, 247, 249, 1);
  border-radius: 1vw;
  padding: 2vw;
  margin-bottom: 1.5vw;
  cursor: pointer;
}
.faqEmBody p {
  color: #000;
  font-family: Montserrat;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 2.1875vw */
  letter-spacing: -0.07291666666666666vw;
}
.faqEmBody img {
  width: 2.5vw;
}
.faqEm > p {
  margin-top: 2vw;
  color: var(--, #000);
  font-family: Montserrat;
  font-size: 1.2vw;
  width: 90%;
  overflow: hidden;
  transition: 0.5s;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.875vw */
  letter-spacing: -0.0625vw;
}
@media (max-width: 640px) {
  .FAQ {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    margin-top: 20vw;
    padding-right: 2.5vw;
  }
  .faqEm {
    border: 0.052083333333333336vw solid rgba(245, 247, 249, 1);
    border-radius: 5vw;
    padding: 4vw;
    width: 100%;
    margin-bottom: 3.5vw;
    cursor: pointer;
  }
  .faqEmBody img {
    width: 7.5vw;
  }
  .faqEm > p {
    margin-top: 2vw;
    color: var(--, #000);
    font-family: Montserrat;
    font-size: 4vw;
    width: 90%;
    overflow: hidden;
    transition: 0.5s;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.052083333333333336vw;
  }
  .faqEmBody p {
    color: #000;
    font-family: Montserrat;
    font-size: 4vw;
    font-style: normal;
    font-weight: 600;
    width: 80%;
    line-height: 150%;
    letter-spacing: -0.052083333333333336vw;
  }
  .FAQ h2 {
    color: var(--, #000);
    font-family: Montserrat;
    font-size: 11vw;
    margin-top: 9.5vw;
    margin-bottom: 6.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.052083333333333336vw;
  }
}
