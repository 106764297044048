.leftAuth {
  padding-left: 6vw;
  padding-top: 3.5vw;
}
.auth {
  display: flex;
  justify-content: space-between;
}
.logo {
  width: 11vw;
  margin-bottom: 4.5vw;
}
.navAuth p {
  color: var(--, #000);
  font-family: Inter;
  font-size: 3.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.18229166666666666vw;
}
.navAuth a {
  color: #000;
  text-decoration: none;
}
.navAuth {
  margin-bottom: 1vw;
  display: flex;
  align-items: center;
}
.feeling {
  color: #77828c;
  font-family: Inter;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5625vw */
  letter-spacing: -0.052083333333333336vw;
  margin-bottom: 2vw;
}
.inputAuth p {
  color: #626262;
  font-family: Inter;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 0.7291666666666666vw */
  letter-spacing: -0.052083333333333336vw;
  margin-bottom: 0.5vw;
  margin-left: 1vw;
}
.disabled {
  color: #dde7f0 !important;
}
.navAuth div {
  width: 0.10416666666666667vw;
  background: #0370f3;
  height: 2.5vw;
  margin: 0 1.5vw;
}

.inputAuth input {
  display: flex;
  padding: 0.9vw 1vw;
  align-items: center;

  font-family: Inter;
  font-size: 0.9vw;
  font-style: normal;
  width: 31vw;
  font-weight: 600;
  line-height: 100%; /* 0.8333333333333334vw */
  letter-spacing: -0.052083333333333336vw;
  align-self: stretch;
  border-radius: 3vw;
  background: #f5f7f9;
  border: 0.10416666666666667vw solid #f5f7f9;
  outline: none;
}
.inputAuth {
  margin-bottom: 2vw;
}
.rightAuth {
  background: url(./img/fon.png);
  background-size: cover;
  background-color: #0370f3;
  width: 56vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rightAuth h4 {
  text-align: center;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1vw;
  letter-spacing: -0.10416666666666667vw;
}
.rightAuth p {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5625vw */
  letter-spacing: -0.052083333333333336vw;
}
.rightAuth img {
  width: 38.4375vw;
  margin-left: 2.5vw;
  position: relative;
  z-index: 3;
}
.rightAuth {
  padding-top: 6vw;
}
.login {
  border-radius: 3vw;
  background: #0370f3;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 1vw;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 1.3vw;
  font-style: normal;
  cursor: pointer;
  font-weight: 600;
  line-height: 160%; /* 2vw */
  letter-spacing: -0.0625vw;
}
.hero {
  background-color: #0370f3;
  position: relative;
  height: 100vh;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
}
.bg {
  animation: slide 20s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #0052b5 50%, #09f 50%);
  bottom: 0;
  left: -50%;
  opacity: 0.5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 25s;
}

.bg3 {
  animation-duration: 30s;
}

.content {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.25em;
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  left: 50%;
  padding: 10vmin;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}

h1 {
  font-family: monospace;
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}
.cube {
  position: absolute;
  top: 80vh;
  left: 45vw;
  width: 0.5208333333333333vw;
  height: 0.5208333333333333vw;
  border: solid 0.052083333333333336vw #003db8;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  -webkit-animation: cube 12s ease-in forwards infinite;
  animation: cube 12s ease-in forwards infinite;
}
.cube:nth-child(2n) {
  border-color: #0051f4;
}
.cube:nth-child(2) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  left: 12vw;
  top: 35vh;
}
.cube:nth-child(3) {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  left: 75vw;
  top: 50vh;
}
.cube:nth-child(4) {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
  left: 90vw;
  top: 10vh;
}
.cube:nth-child(5) {
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
  left: 10vw;
  top: 85vh;
}
.cube:nth-child(6) {
  -webkit-animation-delay: 10s;
  animation-delay: 10s;
  left: 50vw;
  top: 10vh;
}

@-webkit-keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}
@media (max-width: 640px) {
  .auth {
    flex-direction: column;
    background: url(./img/bg.png);
    background-size: cover;
  }
  .leftAuth {
    padding: 10vw 5vw;
    background: #fff;
    width: 94vw;
    border-radius: 6vw;
    margin: 7vw auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .hero {
    height: auto;
  }
  .logo {
    width: 50vw;
    margin-bottom: 7.5vw;
  }
  .navAuth p {
    color: var(--, #000);
    font-family: Inter;
    font-size: 8.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.18229166666666666vw;
  }
  .navAuth div {
    width: 0.4416666666666667vw;
    background: #0370f3;
    height: 8.5vw;
    margin: 0 3.5vw;
  }
  .feeling {
    color: #77828c;
    font-family: Inter;
    font-size: 3.7vw;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.052083333333333336vw;
    margin-bottom: 8vw;
  }
  .navAuth {
    margin-bottom: 3vw;
  }
  .inputAuth p {
    color: #626262;
    font-family: Inter;
    font-size: 3.7vw;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.052083333333333336vw;
    margin-bottom: 1.5vw;
    margin-left: 4vw;
  }
  .inputAuth input {
    display: flex;
    padding: 3.9vw 4vw;
    align-items: center;
    font-family: Inter;
    font-size: 4vw;
    font-style: normal;
    width: 100%;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.052083333333333336vw;
    align-self: stretch;
    border-radius: 12vw;
    background: #f5f7f9;
    border: 0.10416666666666667vw solid #f5f7f9;
    outline: none;
  }
  .inputAuth {
    width: 100%;
    margin-bottom: 6vw;
  }
  .login {
    border-radius: 13vw;
    background: #0370f3;
    color: #fff;
    display: flex;
    justify-content: center;
    padding: 5vw;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 4.3vw;
    font-style: normal;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    line-height: 160%;
    letter-spacing: -0.0625vw;
  }
  .rightAuth {
    width: 100%;
    background: none;
    padding-top: 0;
    padding-bottom: 10vw;
  }
  .rightAuth img {
    width: 95%;
    margin-left: 4.5vw;
    position: relative;
    z-index: 3;
  }
  .rightAuth h4 {
    text-align: center;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 5vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1vw;
    letter-spacing: -0.10416666666666667vw;
  }
  .bg {
    display: none;
  }
  .rightAuth p {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 3vw;
    font-style: normal;
    width: 100%;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.052083333333333336vw;
  }
}
